<template>
    <div class="playingRecord">
        <!-- 试玩记录 -->
        <UserMsg class="userMsg_component" :userMsg="userMsg" />
        <div class="playingRecord_content">
            <PartitionTitle class="partitionTitle" title="最近玩过" />
            <div v-if="defaultPageStatus">
                <el-scrollbar ref="scrollbarMax" class="playingRecord_scorll contentScorll_max">
                    <!-- 大屏 -->
                    <div class="playingRecord_list">
                        <div v-for="(item, index) in playingListData" :key="index"><!-- 游戏图片 -->
                            <div @click="routeDetail(item.game_id)">
                                <img :src="item.image" />
                            </div>
                            <!-- 游戏内容 -->
                            <div>
                                <div>
                                    <p :title="item.name">
                                        {{ item.name.length > 6? item.name.substr(0, 6) + "...": item.name }}
                                    </p>
                                    <p>{{ item.time }}</p>
                                </div>
                                <!-- 启动按钮 -->
                                <button
                                    class="startBtn"
                                    @click="startGame(item.game_id)">
                                    启动云游戏
                                </button>
                            </div>
                        </div>
                        <div class="seizeSeat_box"></div>
                    </div>
                    <div v-if="loading" class="list_loading"></div>
                </el-scrollbar>
                <el-scrollbar ref="scrollbarMin" class="playingRecord_scorll contentScorll_min" :style="'height:' + minScorllHeight">
                    <!-- 小屏 -->
                    <div class="playingRecordMin">
                        <div v-for="(item, index) in playingListData" :key="index">
                            <!-- 游戏图片 -->
                            <div class="min_image" @click="routeDetail(item.game_id)">
                                <img :src="item.image" />
                            </div>
                            <!-- 游戏内容 -->
                            <div class="min_content">
                                <p :title="item.name">{{ item.name }}</p>
                                <p>{{ item.time }}</p>
                            </div>
                            <!-- 启动按钮 -->
                            <button
                                class="startBtn"
                                @click="startGame(item.game_id)">
                                启动云游戏
                            </button>
                        </div>
                    </div>
                    <div v-if="loading" class="list_loading"></div>
                </el-scrollbar>
            </div>
            <div v-else class="defaultPage">
                <div class="defaultPage_content">
                    <img src="@/assets/images/user/bg-default-play.png" />
                    <p>您还没有玩过任何游戏</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PartitionTitle from '@/components/officialWebsite/PartitionTitle';
import launchGameUtil from "@/components/cloudGameLauncher/launchGameUtil";
import UserMsg from '@/components/officialWebsite/UserMsg';

export default {
    name: "PlayingRecord",
    props: {
        userMsgStatus: {
            //设置点击后加载请求
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userMsg: {
                avatar: "", //用户头像
                name: "",   //用户名
                age: 0,     //用户年龄
                sex: 1,     //性别
            },
            page: 1,        //统计页数
            loading: false, //加载动画
            preNum: 0,
            minScorllHeight: "100px",
            playingListData: [], //游戏记录列表
            defaultPageStatus: true,
        };
    },
    watch: {
        //  获取用户与试玩数据
        userMsgStatus(state) {
            if (!state) return;
            // 点击后获取存储在store 的基础用户信息
            this.userMsg = this.$store.state.login.user;
            // 发送请求获取游戏记录
            this.loading = true;
            this.getRecordData();
        },
    },
    mounted() {
        this.scrollMore(this.$refs.scrollbarMax.wrap);
        this.scrollMore(this.$refs.scrollbarMin.wrap);
    },
    methods: {
        routeDetail(gameId){
            this.$router.push({path: '/game/' + gameId})
        },
        startGame(gameId){
            launchGameUtil.startGameReview(gameId, this);
        },
        scrollMore(ele) {
            ele.onscroll = () => {
                // 滚动到底，触发事件
                if (ele.scrollTop + ele.clientHeight >= ele.scrollHeight - 1 && !this.loading ) {
                    //防止重复触发，滚动移位 1
                    ele.scrollTop = ele.scrollTop - 1;

                    this.loading = true;
                    this.getRecordData();
                }
            };
        },
        getRecordData() {
            this.$API.getPlayingRecordData({ page: this.page })
                .then((response) => {
                    let res = response.data;
                    if (res.status) {
                        if (res.data.list.length > 0) {
                            this.playingListData = [...this.playingListData, ...res.data.list];

                            // 小屏列表小于，不设置滚动，大于 X 个就设置滚动
                            this.minScorllHeight =
                                res.data.list.length < 6
                                ? res.data.list.length * 72 + "px"
                                : (this.minScorllHeight = "466px");

                            // 最后一页销毁滚动监听
                            if (this.page == res.data.meta.last_page) {
                                this.$refs.scrollbarMax.wrap.onscroll = null;
                                this.$refs.scrollbarMin.wrap.onscroll = null;
                            }

                            this.page += 1;
                        } else {
                            // 进入无数据后
                            this.minScorllHeight = "350px";
                            this.defaultPageStatus = false;
                        }
                        this.loading = false;
                    }
                });
        },
    },
    components: {
        UserMsg,
        PartitionTitle,
    },
};
</script>

<style lang="scss" scoped>
.playingRecord {
    font-weight: 400;
    padding: 0 40px;
    font-family: "PingFangSC-Regular", sans-serif;

    > :nth-child(1) {
        margin-top: 5px;
    }
    // 记录列表
    // 滚动设置
    .playingRecord_scorll {
        width: 100%;
        height: 580px;
        // 内容列表
        .playingRecord_list {
            display: flex;
            flex-wrap: wrap;
            > div {
                width: 264px;
                height: 222px;
                margin-top: 10px;
                border-radius: 8px;
                flex: none;
                overflow: hidden;
                background-color: #fff;
                > div:nth-child(1) {
                    width: 264px;
                    height: 148px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                > div:nth-child(1) :hover{
                    cursor: pointer;
                }
                > div:nth-child(2) {
                    width: 264px;
                    height: 74px;
                    padding: 0 16px;
                    display: flex;
                    justify-content: flex-start;
                    > div {
                        width: 136px;
                        height: 50px;
                        transform: translateY(24%);
                        p {
                            margin-bottom: 0;
                        }
                        > :nth-child(1) {
                            font-size: 18px;
                            font-weight: 500;
                            color: #d5a561;
                            font-family: PingFangSC-Medium, sans-serif;
                        }
                        > :nth-child(2) {
                            font-size: 12px;
                            color: #606062;
                        }
                    }

                    .startBtn {
                        width: 82px;
                        height: 34px;
                        padding: 2px;
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 16px;
                        border-radius: 4px;
                        border: none;
                        outline: none;
                        color: #fff;
                        transform: translateY(59%);
                        background: linear-gradient(154deg,#fad8a1 0%,#e3af6d 100%);
                    }
                    .startBtn:hover {
                        background: #e3af6d;
                    }
                }
            }
            .seizeSeat_box {
                background-color: none;
                height: 0;
            }
        }
        // 小屏内容
        .playingRecordMin {
            width: 100%;
            > div {
                width: 100%;
                height: 48px;
                margin-bottom: 24px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .min_image {
                    width: 48px;
                    height: 48px;
                    margin-right: 8px;
                    border-radius: 4px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .min_content {
                    > p {
                        margin-bottom: 0;
                    }
                    > :nth-child(1) {
                        height: 20px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #d5a561;
                        margin-bottom: 5px;
                    }
                    > :nth-child(2) {
                        height: 14px;
                        font-size: 10px;
                        color: #606062;
                    }
                }
                button {
                    width: 74px;
                    height: 34px;
                    margin-top: 8px;
                    font-size: 11px;
                    line-height: 0px;
                    color: #ffffff;
                    outline: none;
                    margin-left: auto;
                    border: 1px solid;
                    border-radius: 3px;
                    background: linear-gradient(154deg,#fad8a1 0%,#e3af6d 100%);
                }
            }
        }
    }
}

// 缺省图
.defaultPage {
    width: 100%;
    margin-top: 40px;
    // background-color: navy;
    .defaultPage_content {
        width: 260px;
        height: 240px;
        margin: 0 auto;
        text-align: center;
        color: #adadb0;
    }
}

@media screen and (min-width: 1200px) {
    .playingRecord_list {
        > div,>div>div{
            width: 249px !important;
        }
        > div:nth-of-type(3n + 1) {
            margin-right: 8px;
        }
        > div:nth-of-type(3n + 2) {
            margin-left: 8px;
            margin-right: 8px;
        }
        > div:nth-of-type(3n + 3) {
            margin-left: 8px;
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .playingRecord_list {
        > div:nth-of-type(2n + 1) {
            margin-right: 8px;
        }
        > div:nth-of-type(2n + 2) {
            margin-left: 8px;
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 1000px) and (min-width: 700px) {
    .playingRecord {
        .playingRecord_scorll {
            .playingRecord_list {
                justify-content: center;
                align-items: center;
                > div,>div>div{
                    width: 245px !important;
                }
                > div:nth-of-type(2n + 1) {
                    margin-right: 8px;
                }
                > div:nth-of-type(2n + 2) {
                    margin-left: 8px;
                }
            }
        }
    }
    .contentScorll_min {
        display: none;
    }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
    .playingRecord {
        padding: 0;
        .playingRecord_scorll {
            padding: 0 16px;
            background-color: white;
        }
    }
    .userMsg_component,
    .playingRecord .contentScorll_max,
    .partitionTitle {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}
@media screen and (max-width: 500px) and (min-width: 350px) {
    .playingRecord {
        padding: 0;
        .playingRecord_scorll {
            padding: 0 16px;
            background-color: white;
        }
    }
    .userMsg_component,
    .playingRecord .contentScorll_max,
    .partitionTitle {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}
@media screen and (max-width: 350px) {
    .min_content {
        width: 50%;
    }
    .playingRecord {
        padding: 0;
        .playingRecord_scorll {
            padding: 0 16px;
            background-color: white;
        }
    }
    .userMsg_component,
    .playingRecord .contentScorll_max,
    .partitionTitle {
        display: none;
    }
    .contentScorll_min {
        display: block;
    }
}

//加载动画
.list_loading {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #d5a561;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.list_loading.hide {
    display: none;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
