<template>
    <!-- 
        个人用户中心
        用户个人信息展示
        ：头像，年龄，性别
     -->
    <div class="msg-data">
        <div class="msg-photo">
            <div>
            <img :src="userMsg.avatar" alt="" />
            <!-- 隐藏的修改功能 -->
            <div><button type="button">修改头像</button></div>
            </div>
        </div>
        <div class="msg-content">
            <p class="msgShow_status">
            <span v-show="userNameStatus">{{ userMsg.name }}</span>
            <input
                v-show="!userNameStatus"
                class="msgShow_status"
                maxlength="16"
                type="text"
                min="4"
                v-model="userNameVal"
                @keyup.enter="nameStatus_dialog = true"
                />
                <i v-show="userNameStatus" @click="userNameStatus = false" class="msgShow_status"></i>
            </p>
            <p v-show="!userNameStatus" class="ruleHelp">限4-16个字符，可由中英文字、数字、"_"、"-"组成，以英文字母或汉字开头</p>
            <p v-show="!userNameStatus" class="msgBtn"> 
                <button type="button"  @click="msgBtn_rule">确定</button> 
                <button type="button" @click="()=> userNameStatus = true">取消</button> 
            </p>
            <!-- <p>ID：{{ userMsg.id }}</p> -->
            <p :style="{marginTop: userNameStatus?'40px':'5px'} ">
            <span>年龄:</span><span>{{ typeof userMsg.age == 'number'?userMsg.age:'未知' }}</span> <span>性别:</span><span>{{ userMsg.sex }}</span>
            </p>
        </div>

        <!-- 修改邮箱弹框 Start-->
        <el-dialog
            title="温馨提示"
            :visible.sync="nameStatus_dialog"
            center
            width="500px">
                <div class="editName_body">
                    名称每月仅可修改一次，是否确认修改？
                </div>
                <el-button
                    class="el-button--warning"
                    type="primary"
                    @click="changeUserName"
                    >确认
                </el-button>
                <el-button
                    class="editName_default"
                    type="primary"
                    @click="() => nameStatus_dialog = false"
                    >取消
                </el-button>
        </el-dialog>
        <!-- End -->
    </div>


</template>

<script>
    export default {
        name: "News",
        props: {
            userMsg:{
                type: Object,
                default: {
                    avatar: '',         //头像
                    name:'',
                    age: 0,             //年龄
                    sex: '未认证'              //性别
                }
            }
        },
        data () {
            return {
                userNameVal: '',
                userNameStatus:true,    //用户名修改状态
                nameStatus_dialog:false
            }
        },
        mounted(){
            
           this.userNameVal = this.userMsg.name;
        },
        methods: {
           changeUserName(){
            let status = !this.userNameStatus;

            // 状态为真，同时输入不为空值，提交
            status && !!this.userNameVal
                ? (() => {
                    // 验证相同
                    if(!this.msgBtn_rule()) return false;
                    //有值提交
                    this.$API.submitUserCenterData({ name: this.userNameVal })
                        .then((response) => {
                            let res = response.data;
                            if (res.status == 1) {
                                let name = res.data?.name,
                                    nObj = this.$store.state.login.user;
                                    nObj.name = name;
                        
                                this.userMsg.name = name;
                                this.$store.commit("MESSAGE_TYPE", true);
                                this.$store.commit("MESSAGE_TEXT", "名称修改成功！");
                                this.$store.commit("UPDATE_USER", nObj);

                                this.nameStatus_dialog = false;
                                this.userNameStatus = true;
                            }else{
                                this.userNameStatus = true;
                                this.nameStatus_dialog = false;
                                this.userNameVal = this.userMsg.name;
                            }
                        });
                })()
                : status
                ? (() => {
                    //返回空值处理
                    this.$store.commit("MESSAGE_TEXT", "请输入要修改的名称！");
                })()
                : '';
           },
            msgBtn_rule(){
                if(this.userMsg?.name.trim() === this.userNameVal.trim()){
                    this.$store.commit("MESSAGE_TEXT", "请输入新的名称，不可与旧名称相同！");
                    return false;
                }else{
                    this.nameStatus_dialog = true
                    return true;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    $wPhoto : 120px;    //头像大小 

    // 头像个人信息部分
    .msg-data{
        padding: 40px 0 16px 0;
        >div{
            display: inline-block;
        }
        // 左侧头像样式
        .msg-photo{
            margin-right: 40px;
            >div{
                width: $wPhoto;
                height: $wPhoto;
                border-radius: 50%;
                overflow: hidden;
                position: relative;
                >img{
                    width: 100%;
                    height: 100%;
                }
                >div{
                    width: $wPhoto;
                    height: $wPhoto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    background: rgba($color: #000000, $alpha: 0.4);
                    display: none;
                    button{
                        width: 70px; 
                        height: 32px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        font-size: 12px;
                        margin-top: -16px;
                        margin-left: -35px;
                        border-radius: 346px; 
                        outline: none;
                        cursor: pointer; 
                        color: #FAD8A1;
                        border: 1px solid #FAD8A1;
                        background: rgba($color: #000000, $alpha: 0.2);

                    }
                    button:hover{
                        cursor: pointer;
                        background: rgba($color: #000000, $alpha: 0.2);
                    }
                }
            }
        }
        // 右侧信息内容
        .msg-content{
            >p{margin-bottom: 0px;}
            >p:nth-child(1){
                height: 33px;
                span{
                    font-size: 24px;
                    line-height: 33px;
                    color: #2F333D;
                }
                input{
                    border: 1px solid #D5A561;
                    border-radius: 5px;
                }
                i{
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                    display: inline-block;
                    background-image: url('../../assets/images/user/i-edit.png');
                }
                i:hover{
                    cursor: pointer;
                }
            }
            .ruleHelp{
                font-size: 12px;
                color: red;
            }
            .msgBtn{
                margin-top: 5px;
                font-size: 12px;
                button{
                    border: none;
                    outline: none;
                }
                :nth-child(1){
                    margin-right: 10px;
                    color: #fff;
                    border-radius: 4px;
                    background: linear-gradient( 154deg, #fad8a1 0%,#e3af6d 100% );
                }
                :nth-child(1):hover{
                    background: #e3af6d;
                }
                :nth-child(2){
                    color: #888888;
                    border-radius: 4px;
                    border:1px solid #adadb0;
                    background-color: #fff;
                  
                }
                :nth-child(2):hover{
                    color: #d5a561;
                    border-color: #d5a561;
                }
            }
            >p:nth-child(4){
                margin-top: 40px;
                color: #888888;
                :nth-child(2),
                :nth-child(4){
                    margin-left: 20px;
                    color: #606062;
                }
                
                :nth-child(3){
                    margin-left: 40px;
                }
            }
        }
    }

    /deep/.el-dialog{
        .el-dialog__body{
            text-align: center;
            .editName_body{
                color: rgb(255, 115, 115);
                font-size: 16px;
                font-weight: bold;
                margin-top: 10px;
                padding-bottom: 50px;
            }
            .el-button--warning{
                outline: none !important;
            }
            .editName_default{
                color: #888888;
                border-radius: 4px;
                background-color: #fff;
                border:1px solid #adadb0;
            }
            .editName_default:hover{
                color: #d5a561;
                border-color: #d5a561;
            }
        }
    }

@media screen and (min-width: 1200px) {

}
@media screen and (max-width: 1200px) and (min-width: 1000px) {

}
@media screen and (max-width: 1000px) and (min-width: 700px) {
 
}
@media screen and (max-width: 700px) and (min-width: 500px){
        
}
@media screen and (max-width: 500px) {
    .msg-data{
        height: 300px;
        .msg-photo{
            width: 100%;
            >div{
                margin: 0 auto;
            }
        }
        .msg-content{
            width: 100%;
            text-align: center;
        }
    }
}

</style>